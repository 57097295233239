import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import SelectorContainer from '../../../src/common/components/FunnelSelector/Entrada/SelectorContainer'
import SelectorForm from '../../../src/common/components/FunnelSelector/Entrada/SelectorForm'
import Step, {
  findNext,
  InSkipInfo,
} from '../../../src/common/components/FunnelSelector/SelectorSteps'
import SearchNeumaticos from '../../../src/common/components/searchNeumaticosCardHome/index'
import ResponsiveSliderBanner from '../../../src/common/components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Title from '../../../src/common/components/Title'
import { reset } from '../../../src/common/context/actions/selectorActions'
import AppContext, { IAppState } from '../../../src/common/context/context'
import { selectSkipInfo } from '../../../src/common/context/selectors/selectorSelectors'
import { temporadaMapping } from '../../../src/specific/helpers/gtmReporter'
import { getImages, ImageInputData } from '../../../src/common/utils/imagesS3'
import { SeoData } from '../../../src/common/types/Seo'
import route from '../../../src/common/utils/route'
import { getFullCompany } from '../../../src/common/utils/getCompany'
import { locale } from '../../../src/common/i18n'

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}
interface InSeoData {
  seoData: SeoData
}
interface Props {
  styles: { [k: string]: string }
  data?: InSeoData | ImageInputData
  tablet?: boolean
  desktop?: boolean
}

const LandingPremium = ({ styles, data }: Props) => {
  const banners_mobile = getImages(
    data[`${'banners'}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`${'banners'}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <div className={styles.container}>
      <Title text="Pneus Premium" />
      <Selector styles={styles} />
      <article className={styles.description}>
        <p className={styles.main_description}>
        Na Rodi Motor, somos profissionais dedicados ao mundo do pneu e da manutenção do automóvel com mais de 30 anos de experiência no setor. Graças ao conhecimento adquirido ao longo dos anos, trabalhamos com as marcas de pneus mais prestigiadas do mercado, podendo oferecer os melhores preços com uma excelente qualidade de serviço.
        </p>

        <ResponsiveSliderBanner
          desktopImages={banners_desktop}
          mobileImages={banners_mobile}
          sizesVariant={'banner_landings'}
        />

        <section className={styles.section}>
          <h2>Grande seleção de Pneus Premium </h2>
          <p>
          Na nossa seleção de Marcas Premium, encontrará as melhores e mais reconhecidas marcas de pneus. São as marcas de eleição dos condutores mais exigentes que utilizam os seus carros continuamente. São também a melhor escolha para os condutores que procuram uma excelente qualidade e um elevado desempenho do pneu.
          </p>
        </section>
        <section className={styles.section}>
          <p>
          Estes fabricantes são reconhecidos por submeterem os seus modelos de pneus a múltiplos testes a fim de demonstrarem a sua liderança e diferenciação no setor. Oferecem as melhores prestações em termos de durabilidade, aderência e estabilidade em todos os tipos de estradas e em todas as situações atmosféricas.
          </p>
        </section>
        <section className={styles.section}>
          <p>
          As marcas premium destacam-se por oferecerem prestações na rotulagem de controlo da União Europeia. Os seus modelos de pneus são sempre categorizados com as melhores pontuações nos testes de controlo, a sua eficiência energética situa-se entre a classificação A e B, a sua travagem em piso molhado é incomparável com marcas de qualidade inferior obtendo sempre a classificação A (a classificação mais alta) e o seu nível de ruído é inferior a 70 dB em todos os seus modelos.
          </p>
        </section>
        <section className={styles.section}>
          <p>
          Nesta categoria, encontrará marcas de pneus de renome tais como a Michelin, Continenal, Pirelli, , 
          Goodyear, Bridgestone e Dunlop.
          </p>
        </section>
      </article>
    </div>
  )
}

export default LandingPremium

interface SelectorProps {
  styles: { [k: string]: string }
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}

const ConnectedSelector = ({ dispatch, styles, skipInfo }: SelectorProps) => {
  const { selectorState } = useContext(AppContext)
  // Initialize selector values
  useEffect(() => {
    dispatch(
      reset({
        keepValues: false,
        marca: null,
        origen: route('landing-premium'),
        temporada: { selectable: false, value: 'verano' },
        tipoVehiculo: null,
      })
    )
  }, [])

  return (
    <React.Fragment>
      <SearchNeumaticos
        gtmData={{
          action: 'verano',
          runflat: selectorState.runflat.value,
          tipo_neumaticos: temporadaMapping[selectorState.temporada.value],
        }}
      />

      <div className={styles.selector_container}>
        <SelectorContainer showMarcas={false} origin={route('landing-premium')}>
          {({ ...props }) => (
            <SelectorForm
              showTemporada={false}
              fromModal={false}
              handleSubmit={() => {
                const next = findNext(Step.SELECT_MARCAS, 'desktop', skipInfo)
                navigate(next.routes.desktop)
              }}
              {...props}
            />
          )}
        </SelectorContainer>
      </div>
    </React.Fragment>
  )
}
const Selector = connect((state: IAppState) => ({
  skipInfo: selectSkipInfo(state),
}))(ConnectedSelector)
