import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../../../src/common/components/Layout'
import { SeoData } from '../../../../src/common/types/Seo'
import { t } from '../../../../src/common/i18n'
import route from '../../../../src/common/utils/route'
import styles from '../../../../src/common/pages/landing.module.scss'
import LandingPremium from '../../static/landing-premium'
import ListaMarcas from '../../../../src/common/components/landingNeumaticos/ListaMarcas'
import { useSelectedMenu } from '../../helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../constants/menu'

interface Props {
  data: {
    seoData: SeoData
  }
}
export default function Index({ data }: Props) {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)
  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos'),
        },
        {
          display: t('bread_crumb.premium'),
        },
      ]}>
      <LandingPremium styles={styles} data={data} />
      <div className={styles.seccionMarcas}>
        <div className={styles.category_marcas}>
          <ListaMarcas type="PREMIUM" />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query LandingPremiumQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    banners_desktop: allS3Asset(
      filter: { Key: { glob: "pneus/premium-pneus.jpg" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_mobile: allS3Asset(
      filter: { Key: { glob: "pneus/pneus-premium-carro.jpg" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
